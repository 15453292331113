"use client";
import { useEffect, useState } from "react";

import { User } from "@supabase/supabase-js";
import { createClient } from "../supabase/client";

export const useSession = () => {
  const [user, setUser] = useState<User | null>();
  const [loading, setLoading] = useState(true);

  const supabase = createClient();

  const fetchUser = async () => {
    setLoading(true);
    const {
      data: { user },
      error,
    } = await supabase.auth.getUser();
    if (error || !user) {
      return;
      // redirect("/login");
    }

    setUser(user);
    setLoading(false);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const updateUser = async () => {
    await fetchUser();
  };

  return { loading, updateUser, user: user };
};
