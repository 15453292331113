"use client";
import { useSession } from "@/lib/hooks/use-session";
import { Flex } from "@radix-ui/themes";
import { useTheme } from "next-themes";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";

export default function AppHeader({
  MobileNavigation,
  SearchBar,
  DesktopNavigation,
  PersonArea,
}: {
  MobileNavigation: React.ReactNode;
  SearchBar?: React.ReactNode;
  DesktopNavigation: React.ReactNode;
  PersonArea: React.ReactNode;
}) {
  const { push } = useRouter();
  const { user } = useSession();
  const { resolvedTheme } = useTheme();
  const [logoUrl, setLogoUrl] = useState(
    "/images/tixi-logo-hor-colour.min.svg",
  );

  useEffect(() => {
    console.log("finalTheme", resolvedTheme);
    setLogoUrl(
      resolvedTheme === "dark"
        ? "/images/tixi-logo-hor-colour.dark.min.svg"
        : "/images/tixi-logo-hor-colour.min.svg",
    );
  }, [resolvedTheme]);

  return (
    <div className="fixed top-0 left-0 right-0 grid w-full md:grid-cols-[1fr] lg:grid-cols-[1fr] z-50 backdrop-blur-xl bg-muted/4">
      <header className="flex h-14 items-center md:justify-between gap-4  px-4 lg:h-[60px] lg:px-6 border-b">
        {MobileNavigation}
        <Flex className="w-full">
          <Flex className="mr-8">
            <Link
              href="/"
              className="flex items-center gap-2 font-semibold min-w-[60px]"
            >
              <Image
                src={logoUrl}
                width={70}
                height={24}
                alt="tixi Logo"
                priority
              />
            </Link>
          </Flex>

          {DesktopNavigation}
        </Flex>

        <div className="md:min-w-[400px] hidden md:block">{SearchBar}</div>

        <Flex justify="end" className="md:w-min w-full">
          {PersonArea}
        </Flex>
      </header>
    </div>
  );
}
